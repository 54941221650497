.credit-history-table {
    max-width: 400px;
    width: 100%;
    border-spacing: 0;
    margin-bottom: 48px;
    th {
        padding: 16px;
        text-align: left;
        font-weight: normal;
        color: $color-white;
        background-color: $color-primary-darkest;
    }
    td {
        padding: 16px;
        text-align: left;
        color: $color-primary-darkest;
        background-color: rgba(255, 255, 255, 0.3);
        span {
            &.credit-negative {
                color: $color-danger;
            }
            &.credit-positive {
                color: $color-success;
            }
        }
    }
    tr + tr {
        td {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}
