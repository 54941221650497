.delivery-form {
    width: 100%;

    .sizes-form-row.is-sizes {
        margin-bottom: 30px;
    }

    .checkbox {
         margin-bottom: 0;
    }
}

.delivery-form-row {
    margin-bottom: 45px;
}

.delivery-methods-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px -30px;

    @media only screen and (min-width: $screen-md) {
        margin: 0 -20px -30px;
    }
}

.delivery-methods-row-column {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;

    @media only screen and (min-width: $screen-md) {
        width: 50%;
        padding: 0 20px;
    }

    &.is-courier {
        width: auto;
        display: flex;
        flex-wrap: wrap;
        .radio {
            margin-right: 20px;
        }
    }
}

.courier-header-error {
    color: $color-danger;
}

#courier-error {
    color: $color-danger;
    width: 100%;
    padding-left: 22px;
    display: none;
    &.visible {
        display: block;
    }
}
