.button {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: 48px;
    padding: 12px 25px 14px;
    border: none;
    border-radius: 50px;
    border: $border-dark;
    background-color: $color-primary-darkest;
    color: $color-white;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &.is-big {
        width: 310px;
        height: 83px;
        padding: 20px 0 25px;
        line-height: 35px;
        font-size: 27px;

        .icon {
            font-size: 12px;
            vertical-align: middle;
        }
    }

    .icon {
        font-size: 10px;
        vertical-align: 0;
    }

    .icon-right {
        margin-left: 5px;
    }

    .icon-left {
        margin-right: 5px;
    }

    &.is-primary {
        border: $border-primary-darker;
        background: $color-primary-darker;
    }

    &.is-danger {
        border: $border-danger-light;
        background: $color-danger-light;
    }

    &.is-warning {
        border: $border-warning;
        background: $color-warning;
    }

    &.is-success {
        border: $border-success-light;
        background: $color-success-light;
    }

    &.is-transparent {
        border: $border-dark;
        color: $color-primary-darkest;
        background-color: transparent;
    }

    &.is-grey {
        border: $border-white;
        background: $color-grey-transparent;
        color: $color-primary-darkest;
        font-weight: normal;
    }
}
