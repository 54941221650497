.homepage-wrap {
    @media only screen and (min-width: $screen-md) {
        margin-top: 30px;
    }
}

.homepage-title {
    display: none;
    margin-bottom: 45px;

    @media only screen and (min-width: $screen-sm) {
        display: block;
    }
}

.homepage-form__title {
    margin-bottom: 45px;
}

.homepage-form-wrap {
    display: flex;
    margin-bottom: 90px;

    @media only screen and (min-width: $screen-sm) {
        margin-bottom: 85px;
    }

    .side-divider {
        display: none;
        margin-right: 40px;

        @media only screen and(min-width: $screen-sm) {
            display: block;
        }
    }
}

.statistics-wrap {
    background: $color-white;
    padding: 40px 0 0;
    position: relative;
    z-index: -1;

    @media only screen and (min-width: $screen-sm) {
        padding: 90px 0;
    }
}

.statistics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: $screen-sm) {
        flex-direction: row;
        align-items: flex-end;
    }
}

.statistics-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    text-align: left;

    @media only screen and (min-width: $screen-sm) {
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
        padding: 0 50px;
        margin-bottom: 0;
    }

    .title-secondary {
        @media only screen and (max-width: $screen-sm) {
            order: 2;
        }
    }
}

.statistics-column__img {
    margin-bottom: 30px;

    @media only screen and (max-width: $screen-sm - 1) {
        order: 2;
        margin-right: 20px;
        margin-bottom: 0;
    }
}

.statistics-column__title {
    @media only screen and (max-width: $screen-sm - 1) {
        width: 100%;
        order: 0;
        margin-bottom: 5px;
        text-align: center;
    }
}

.homepage-video {
    margin-top: 25px;
    text-align: center;

    @media only screen and (min-width: $screen-sm) {
        margin-top: 40px;
    }
}

.homepage-video__iframe {
    width: 100%;
    max-width: 820px;
    height: 257px;
    margin: 0 auto;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    @media only screen and (min-width: $screen-sm) {
        height: 510px;
    }
}

.homepage-video__title {
    padding: 0 30px;
    margin-bottom: 25px;

    @media only screen and (min-width: $screen-sm) {
        margin-bottom: 35px;
    }
}

.homepage-video__buttom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 35px 30px;
    background: $color-white;
    height: 210px;
    margin-top: -120px;

    @media only screen and (max-width: $screen-sm) {
        display: none;
    }
}
