.order-info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.order-info-column {
    width: 100%;
    margin-bottom: 30px;

    @media only screen and (min-width: $screen-md) {
        width: 50%;
        margin-bottom: 50px;

        &:nth-child(3n+1) {
            padding-right: 40px;
        }

        &:nth-child(2) {
            padding-left: 40px;
        }
    }
}

.order-info-column-fields-item {
    width: 100%;    
    padding: 0 7px;

    &.is-half-width {
        width: 50%;
    }

    &.is-with-button {
        display: flex;

        .field-container {
            width: 75%;
        }

        .order-info-column-fields-item-button {
            width: 25%;
        }
    }

    &.is-address {
        width: auto;
        flex-grow: 1;
    }

    &.is-name {
        width: auto;
        flex-grow: 1;
    }

    &.is-post-code {
        width: 170px;

        @media only screen and (min-width: $screen-lg) {
            width: 184px;
        }
    }

    @media only screen and (max-width: $screen-md - 1) {
        width: 100%;

        &.is-half-width,
        &.is-address,
        &.is-post-code {
            width: 100%;
        }
    }
}

.order-info-column-fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
}

.order-info-column-fields-item-button {
    width: 148px;
    padding: 0 7px;

    .button {
        width: 100%;
        padding: 12px 0 14px;

        span.is-desktop {
            @media only screen and (max-width: $screen-md - 1) {
                display: none;
            }
        }
    }

    @media only screen and (max-width: $screen-md - 1) {
        width: 70px;

        .icon {
            margin: 0;
        }
    }
}

.order-info-column-fields-errors {
    padding: 0 7px;
    color: $color-danger;
    font-size: 12px;

    ul {
        list-style: none;
        padding: 0;
    }
}
