.orders-pagination {
    padding-top: 48px;
    padding-left: 42px;
    .item {
        width: 50px;
        height: 50px;
        display: inline-block;
        text-align: center;
        padding: 16px;
        margin: 0 6px;
        border-radius: 50px;
        line-height: 1;
        font-weight: 500;
        background-color: $color-grey-transparent;
        color: $color-primary-darkest;
        &.disabled {
            display: none;
        }
        &.active {
            background-color: $color-primary-darkest;
            color: $color-white;
        }
    }
}
