.authentication-wrapper {
    max-width: 448px;
    margin: 0 auto;
    padding: 148px 32px 40px;

    @media only screen and (max-width: $screen-sm) {
        padding-top: 0;
    }

    .authentication-links {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .authentication-buttons {
        margin-top: 32px;
    }

    .button {
        margin: 10px 0;
    }

    button.button {
        margin-right: 32px;
    }
}
