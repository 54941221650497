.footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px 0 60px;

    @media only screen and(min-width: $screen-md) {
        flex-wrap: nowrap;
    }
}

.footer-logo-wrap {
    img {
        height: 47px;
    }

    @media only screen and(min-width: $screen-md) {
        img {
            height: 67px;
        }
    }
}

.footer-nav-column {
    width: 25%;

    @media only screen and(max-width: $screen-md - 1) {
        width: 50%;
        margin-bottom: 75px;
    }

    &.is-first {
        width: 30%;

        .footer-nav__item {
            margin-left: 20px;
        }

        @media only screen and(max-width: $screen-md - 1) {
            width: 100%;

            .footer-nav__item {
                margin-left: 0;
            }
        }
    }

    &.is-last {
        width: 20%;

        @media only screen and(max-width: $screen-md - 1) {
            width: 100%;
            margin-bottom: 0;
        }
    }
}

.footer-nav-column__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 67px;
    margin-bottom: 40px;
    font-weight: bold;

    @media only screen and(max-width: $screen-md - 1) {
        height: auto;
        margin-bottom: 25px;
    }
}

.footer-nav__item {
    margin-bottom: 5px;
}

.footer-nav__item-link {
    display: flex;
    padding: 5px 0;

    &.has-border span {
        padding: 5px 0;
        border-bottom: $border-dark;
    }

    img {
        margin-right: 25px;
    }

    @media only screen and(max-width: $screen-md - 1) {
        padding: 10px 0;
    }
}
