.confirm-info-row {
    &__methods {
        display: flex;
        flex-wrap: wrap;
        .radio {
            margin-right: 20px;
        }
    }
}

.display-none {
    display: none;
}

.toggle-sender-info {
    display: none;
    &--active {
        display: block;
    }
}

.validation-text {
    color: $color-danger;
}

.text-center {
    text-align: center;
}

.autocomplete-list {
    margin: 0;
    padding: 0;
    position: absolute;
    background: $color-white;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 51px;
    max-height: 250px;
    overflow-y: auto;
    box-shadow: $box-shadow-2;

    li {
        margin: 0;
        list-style: none;
        padding: 15px 20px;
        cursor: pointer;
        border-top: 1px solid darken($color-white, 5%);
        transition: $transition-fast;

        &:hover, &:focus {
            background: darken($color-white, 5%);
            transition: $transition-fast;
        }

        &:first-of-type {
            border-top: 0;
        }
    }

    b, .autoComplete_highlighted {
        font-weight: 700;
    }
}

.feature-list {
    margin: 0;
    padding: 0;
    margin-top: 40px;

    &__item {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        margin-top: 30px;

        &__icon {
            width: 60px;
            height: auto;

        }
        &__title {

            margin-left: 40px;
        }
    }
}

.text-cta {
    margin: 50px 0;
    border-radius: 50px;
    background: $color-white;
    padding: 20px;

    @media (min-width: $width-md) {
        padding: 30px 40px;
    }
}

.contact-grid {
    margin-top: 40px;
    margin-bottom: 60px;

    @media (min-width: $width-md) {
        margin-bottom: 100px;
        display: flex;
    }
    &__item {

        @media (min-width: $width-md) {
            width: 50%;
        }

        &__content {

            @media (min-width: $width-md) {
                max-width: 385px;
            }
        }
        &__title {

            font-weight: 300;

            @media (min-width: $width-md) {
                font-size: 32px;
                line-height: 40px;
            }
        }
    }
}

.map-section {
    width: 100%;
    height: 40vw;
    max-height: 700px;

    @media (min-width: $width-md) {
        height: 246px;
    }
}

.info-list {
    margin: 0;
    padding: 0;
    margin-top: 40px;

    &__item {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: flex-start;
        margin-top: 30px;

        &__icon {
            width: 15px;
            height: 15px;
            position: relative;
            top: 6px;
        }

        &__content {
            margin-left: 15px;
            font-weight: 300;
            line-height: 25px;
        }
    }
}

.contact-form {

    &__input {
        margin-top: 20px;

        &--textarea {
            min-height: 120px;
            resize: vertical;
        }
    }
}

.underline-border {
    padding-bottom: 3px;
    border-bottom: $border-dark;
}

.error-msg {
    @media (max-width: $width-md) {
        margin-bottom: 20px;
    }
    color: $color-danger;
    display: none;
    &--active {
        display: block;
    }
}

.text-danger {
    color: $color-danger;
}