$grid-gutter-width: 30px;

$width-xs: 480px;
$width-sm: 768px;
$width-md: 1024px;
$width-lg: 1200px;
$width-xl: 1366px;

$screen-xs: $width-xs;
$screen-sm: $width-sm;
$screen-md: $width-md;
$screen-lg: $width-lg;
$screen-xl: $width-xl;

$container-tablet:(720px + $grid-gutter-width);
$container-sm: $container-tablet;

$container-desktop: (940px + $grid-gutter-width);
$container-md: $container-desktop;

$container-large-desktop:   (1140px + $grid-gutter-width);
$container-lg: $container-large-desktop;

$container-xl-desktop:   (1210px + $grid-gutter-width);
$container-xl: $container-xl-desktop;
