.navigation-modal {
    display: flex;
    overflow: hidden;
    height: 100%;
    flex-direction: column;
    background: $color-primary;
}

.navigation-wrap {
    overflow-y: auto;

    .container {
        margin-bottom: 50px;
    }
}

.navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
}

.navigation-item {
    padding: 10px 0 2px;
    line-height: 25px;

    &.is-big {
        font-size: 32px;
        line-height: 40px;

        &.is-last {
            margin-bottom: 50px;
        }
    }

    &.is-active {
        border-bottom: $border-dark;
    }
}
