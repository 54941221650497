.confirm-info {
    width: 100%;
}

.confirm-info-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;

    @media only screen and (min-width: $screen-md) {
        flex-direction: row;
        margin-bottom: 80px;

        &.is-last {
            margin-bottom: 60px;
        }
    }
}

.confirm-info-row-column {
    margin-bottom: 30px;
    line-height: 27px;

    @media only screen and (min-width: $screen-md) {
        margin-bottom: 0;
    }
}
