* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover,
    &:focus {
        outline: none;
    }
}

#app {
    position: relative;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    height: 100%;
}

html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    background: $color-primary;
    font-family: $font-primary;
    font-size: 17px;
    color: $color-primary-darkest;
}

.app-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.page-content {
    flex: 1 0 auto;
}

.page-content-graphic {
    position: absolute;
    pointer-events: none;

    &.is-right {
        top: 310px;
        right: 0;
    }

    &.is-left {
        left: 0;
        top: 795px;
        &--about {
            top: 600px;
        }
        &--contact {
            top: 1100px;
        }
    }

    &--negative-z {
        z-index: -1;
    }

    &.is-transparent {
        z-index: -1;

        &.is-left {
            top: 870px;
        }

        &.is-right {
            top: 150px;
        }
    }

    @media only screen and(max-width: $screen-lg - 1) {
        display: none;
    }
}

.container {
    width: 100%;
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    margin-left: auto;
    margin-right: auto;

    @media only screen and(min-width: $screen-sm) {
        width: $container-sm;
    }

    @media only screen and(min-width: $screen-md) {
        width: $container-md;
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }

    @media only screen and(min-width: $screen-lg) {
        width: $container-lg;
    }

    @media only screen and(min-width: $screen-xl) {
        width: $container-xl;
    }

    &--content {
        max-width: 824px;
    }
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

i {
    font-size: 20px;
}

button {
    font-family: inherit;
    cursor: pointer;
}

a {
    font-family: inherit;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.side-divider {
    position: relative;
    min-height: 100%;
    min-width: 7px;
    margin-top: 3px;
    z-index: $zIndex-side-divider;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        left: 0;
        border-radius: 50%;
    }

    &:before {
        top: -3px;
    }

    &:after {
        bottom: -3px;
    }

    &.is-orange {
        background: $color-warning;

        &:before {
            background: $color-blue-light;
        }

        &:after {
            background: $color-blue;
        }
    }

    &.is-blue {
        background: $color-blue-light;

        &:before {
            background: $color-primary-darker;
        }

        &:after {
            background: $color-blue;
        }
    }
}

.is-bold {
    font-weight: bold;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.content-wrapper {
    position: relative;
    margin-top: 32px;
    @media only screen and(min-width: $screen-md) {
        margin-top: 72px;
        padding-left: 44px;
        border-left: 7px solid $color-warning;
        &:before {
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 7px;
            background-color: $color-blue-light;
            position: absolute;
            top: -2px;
            left: -7px;
        }
        &:after {
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 7px;
            background-color: $color-blue;
            position: absolute;
            bottom: -2px;
            left: -7px;
        }
        &.secondary {
            border-left: 7px solid $color-blue-light;
            &:before {
                background-color: $color-primary-darker;
            }
            &:after {
                background-color: $color-blue;
            }
        }
    }
}
