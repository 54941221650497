.form-input {
    position: relative;
    display: block;
    width: 100%;
    max-width: none;
    padding: 15px 10px 15px 20px;
    border: none;
    background: $color-white;
    color: $color-primary-darkest;
    font-family: inherit;
    font-size: 17px;
    line-height: 21px;

    &:focus {
        outline: none;
    }

    &[type=number] {
        font-weight: bold;
    }

    &::placeholder {
        color: $color-grey-transparent-darkest;
        font-weight: normal;
    }
}
