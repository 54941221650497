.header {
    display: flex;
    align-items: center;
    padding: 30px 0;
}

.header-logo-wrap {
    img {
        height: 47px;
    }

    @media only screen and(min-width: $screen-md) {
        margin-right: 55px;

        img {
            height: 67px;
        }
    }
}

.header-nav {
    display: flex;
    align-items: center;

    &.is-right {
        margin-left: auto;
    }

    &.is-desktop {
        display: none;

        @media only screen and(min-width: $screen-md) {
            display: flex;
        }
    }

    &.is-mobile {
        display: flex;

        @media only screen and(min-width: $screen-md) {
            display: none;
        }
    }
}

.header-nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
    line-height: 25px;
}

.header-nav-item__link {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
}

.header-nav-button {
    padding: 25px 15px;
    margin: -15px;
    border: none;
    background: none;

    span {
        display: block;
        position: relative;
        width: 36px;
        height: 2px;
        background: $color-primary-darkest;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 36px;
            height: 2px;
            left: 0;
            background: $color-primary-darkest;
        }

        &::after {
            top: 10px;
        }

        &::before {
            top: -10px;
        }
    }

    &.is-close span {
        height: 0;

        &::before,
        &::after {
            top: 0;
        }

        &::before {
            transform: rotate(30deg);
        }

        &::after {
            transform: rotate(-30deg);
        }
    }
}
