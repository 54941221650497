.new-user-form {
    padding: 35px;

    @media only screen and (max-width: $screen-md - 1) {
        .order-info-column-fields-item {
            &.is-half-width {
                width: 50%;
            }
        }
    }

    @media only screen and (max-width: $screen-sm - 1) {
        .order-info-column-fields-item {
            &.is-half-width {
                width: 100%;
            }
        }
    }

    .order-info-column-fields-item.is-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .simple-radio {
            margin-bottom: 0;
        }

        a {
            padding: 10px;
            margin: -10px;
            border: none;
            background: none;
        }

        img {
            width: 19px;
        }
    }
}

.new-user-form-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .button {
        margin-bottom: 20px;
    }

    @media only screen and (min-width: $screen-sm) {
        flex-direction: row;

        .button {
            margin-bottom: 0;
        }
    }
}
