.checkout {
    display: flex;
    margin-top: 25px;
    margin-bottom: 150px;

    form {
        width: 100%;
    }

    .side-divider {
        display: none;
        margin-right: 40px;

        @media only screen and(min-width: $screen-sm) {
            display: block;
        }
    }

    @media only screen and (min-width: $screen-md) {
        margin-top: 0;
    }
}

.checkout-steps {
     display: none;

    @media only screen and (min-width: $screen-md) {
        display: flex;
        margin-bottom: 60px;
    }
}

.checkout-steps-item {
    display: flex;
    align-items: center;
}

.checkout-steps-item__icon {
    margin: 0 20px;
    color: $color-grey-transparent-darkest;

    &.is-active {
        color: $color-primary-darkest;
    }
}

.checkout-steps-item__title {
    color: $color-grey-transparent-darkest;

    &.is-active {
        color: $color-primary-darkest;
    }
}

.checkout-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .button + .button {
        margin-left: 40px;
    }

    @media only screen and (max-width: $screen-xs - 1) {
        flex-direction: column-reverse;
        .button {
            width: 100%;
            margin-bottom: 32px;
        }
        .button + .button {
            margin-left: 0;
        }
    }
}

.checkout-column__title {
    margin-bottom: 30px;
}

.checkout-promotion-message {
    margin-bottom: 30px;
    font-size: 15px;
    text-align: right;
}

.checkout-promotion-message-bubble {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background: $color-warning-light;
    color: $color-white;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
}
