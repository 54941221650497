$color-white: #fff;
$color-black: #000;
$color-warning-light: #ffb662;
$color-warning: #f2a148;
$color-danger: #ff0000;
$color-danger-lighter: #f33e3f;
$color-danger-light: #ff6eaa;
$color-success: #05c002;
$color-success-light: #0ddd09;
$color-primary: #e5f1fe;
$color-primary-darker: #7951ef;
$color-primary-darkest: #29284f;
$color-blue-light: #5cc2fb;
$color-blue: #0077f4;
$color-grey: #d2d4e8;

$color-grey-transparent: rgba(41, 40, 79, 0.1);
$color-grey-transparent-darker: rgba(41, 40, 79, 0.3);
$color-grey-transparent-darkest: rgba(41, 40, 79, 0.4);

$border:  1px solid $color-grey-transparent;
$border-dark:  1px solid $color-grey-transparent-darkest;
$border-primary-darker:  1px solid $color-primary-darker;
$border-light:  1px solid $color-primary;
$border-danger: 1px solid $color-danger;
$border-danger-light: 1px solid $color-danger-light;
$border-success-light: 1px solid $color-success-light;
$border-warning: 1px solid $color-warning;
$border-white: 1px solid $color-white;
$border-radio: 1px solid $color-primary-darkest;

$box-shadow: 0px 0px 11px $color-grey-transparent-darker;
$box-shadow-2: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

$font-primary-name: 'Quicksand';
$font-primary: $font-primary-name, sans-serif;

$zIndex-under: 1;
$zIndex-over: 2;
$zIndex-side-divider: 3;
$zIndex-modal: 21;

$transition-fast: 200ms;
