.field-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    .dropdown-icon {
        position: absolute;
        right: 20px;
        font-size: 20px;
        line-height: 50px;
        pointer-events: none;
    }
}

.field-container__field {
    position: relative;
    display: flex;
    width: 100%;
    background: $color-white;

    &.has-error {
        border: $border-danger;
    }

    &.has-left-content {
        .form-input {
            padding-left: 15px;
        }
    }
}

.field-container__label {
    margin: 0;
    font-size: 17px;
    line-height: 50px;

    &.is-right-content {
        padding-right: 15px;
    }

    &.is-left-content {
        padding-left: 20px;
    }
}

.field-container__field-actions {
    display: flex;
    flex-direction: column;
}

.field-container__field-actions-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    min-width: 33px;
    cursor: pointer;
    border: none;
    text-align: center;

    &.is-up {
        background: url('../../../../media/arrow-up.svg') no-repeat center;
        background-color: $color-grey;
        margin-bottom: 1px;
    }

    &.is-down {
        background: url('../../../../media/arrow-down.svg') no-repeat center;
        background-color: $color-grey;
    }

    &:hover,
    &:focus {
        background-color: $color-grey;
    }
}

.field-container__error {
    color: $color-danger;
    font-size: 12px;

    ul {
        list-style: none;
        padding: 0;
    }
}

.field-container-options-wrap {
    position: absolute;
    display: none;
    max-height: 200px;
    width: 100%;
    background: $color-white;
    box-shadow: $box-shadow;
    z-index: $zIndex-over;

    &.is-visible {
        display: block;
    }
}
