.first-visit-modal {
    padding: 40px 35px 66px 40px;

    .first-visit-modal-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 46px;

        .logo {
            width: 190px;
        }

        .close-icon {
            padding: 10px;
            margin: -10px;
            border: none;
            background: none;
        }

        img {
            width: 19px;
        }
    }
    h1 {
        font-size: 52px;
    }
    h2 {
        font-weight: normal;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 40px;
    }
    p {
        line-height: 25px;
        @media only screen and (min-width: $screen-sm) {
            margin-right: 50px;
        }
    }
    .first-visit-modal-actions {
        display: flex;
        flex-direction: column;
        margin-top: 56px;
        .button {
            margin-bottom: 20px;
        }

        @media only screen and (min-width: $screen-sm) {
            flex-direction: row;

            .button {
                margin-bottom: 0;
                margin-right: 46px;
            }
        }
    }
}
