.order-table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 16px;
    font-size: 16px;
    thead {
        color: $color-white;
        background-color: $color-primary-darkest;
        th {
            padding: 16px 12px;
            text-align: left;
        }
    }
    tbody {
        background-color: rgba(255, 255, 255, 0.3);
        font-size: 14px;
        tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            td {
                padding: 16px 12px;

                a {
                    display: flex;
                    &.pink {
                        color: $color-danger-light;
                    }
                    &.orange {
                        color: $color-warning;
                    }
                    &.purple {
                        color: $color-primary-darker;
                    }
                    &.red {
                        color: $color-danger;
                    }
                    img {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

.order-table__tracking {
    margin-bottom: 10px;
    font-weight: 500;
}

.order-table__link {
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
}

.orders-title-status {
    font-size: 17px;
    font-weight: normal;
    display: inline-block;
    margin-left: 20px;
    &.payment-awaiting_payment {
        color: $color-warning;
    }
    &.payment-paid {
        color: $color-success;
    }
}

.order-buttons {
    margin-top: 72px;
    display: flex;
    justify-content: flex-end;
}

@media only screen and(min-width: $screen-md) {
    .order-table-mobile {
        display: none;
    }
}

@media only screen and(max-width: $screen-md - 1) {
    .order-table {
        display: none;
    }
    .order-table-mobile {
        background-color: rgba(255, 255, 255, 0.3);
        padding: 24px 0 12px;
        &+.order-table-mobile {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .order-table-mobile-wrapper {
            display: flex;
            flex-direction: row;
            .order-table-mobile-header {
                div {
                    font-weight: bold;
                    padding: 3px 0 3px 16px;
                }
            }
            .order-table-mobile-data {
                div {
                    padding: 3px 16px;
                    min-height: 27px;
                }
            }
        }
        .order-table-mobile-actions {
            padding: 8px;
            div {
                display: inline-block;
                padding: 4px 8px;
            }
            a {
                &.pink {
                    color: $color-danger-light;
                }

                &.orange {
                    color: $color-warning;
                }

                &.purple {
                    color: $color-primary-darker;
                }

                &.red {
                    color: $color-danger;
                }

                img {
                    margin-right: 8px;
                }
            }
        }
    }
    .orders-title-status {
        display: block;
        margin: 0;
    }
    .order-buttons {
        justify-content: flex-start;
    }
}
