.message.ui {
    padding: 16px;
    margin-bottom: 20px;
    .content {
        display: flex;
        .header {
            padding: 0 4px 0 0;
            font-weight: bold;
        }
        p {
            margin: 0;
        }
    }
    &.negative {
        background-color: rgba(255, 0, 0, 0.24);
        color: #F40000;
    }
    &.success {
        background-color: rgba(58, 182, 0, 0.3);
        color: #3AB600;
    }
}
