.orders-table {
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 16px;
    .orders-header {
        padding: 16px;
        color: $color-white;
        background-color: $color-primary-darkest;
        display: flex;
        justify-content: space-between;
        .orders-header-title {
            font-weight: bold;
            display: inline-block;
            margin-right: 16px;
        }
        .icon-wrapper {
            display: none;
        }
    }
    .orders-data {
        display: flex;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.3);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .orders-data-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1 1 0;
            color: $color-primary-darkest;

            &.orders-data-link {
                flex: 0;
            }

            .orders-data-header {
                font-weight: bold;
                padding: 16px 20px 4px;
            }
            .orders-data-content {
                padding: 4px 20px 16px;
                font-size: 14px;
                &.unpaid {
                    color: $color-warning;
                }
                &.processing {
                    color: $color-success;
                }
            }
        }
    }
    .icon-wrapper {
        padding: 16px 20px;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.no-orders-button {
    padding: 16px 0 48px;
}

@media only screen and(max-width: $screen-md - 1) {
    .orders-table {
        .orders-header {
            .icon-wrapper {
                display: block;
                padding: 16px;
                margin: -16px;
            }
        }
        .orders-data {
            display: table;
            width: 100%;
            padding-bottom: 24px;
            padding-right: 16px;
            .orders-data-wrapper {
                display: table-row;
                &.orders-data-link {
                    display: none;
                }
                .orders-data-header, .orders-data-content {
                    display: table-cell;
                    padding: 8px 0 0 16px;
                }
            }
        }
    }
}
