.preferences-content {
    display: flex;
    padding-bottom: 20px;
    @media only screen and(max-width: $screen-md) {
        flex-direction: column;
    }
    .preferences-column {
        flex: 50%;
        &:first-of-type {
            margin-right: 196px;
            @media only screen and(max-width: $screen-lg) {
                margin-right: 40px;
            }
            @media only screen and(max-width: $screen-md) {
                margin-right: 0;
                margin-bottom: 40px;
            }
        }
        .subtitle {
            margin-top: 0;
        }
        &> form:first-of-type {
            margin-bottom: 72px;
        }
        .button, .checkbox {
            display: block;
        }
        .button-link-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .senders-wrappers {
        display: flex;
        width: 100%;
        .button {
            margin-left: 12px;
        }
    }

    .sender-actions {
        margin-top: -72px;
        padding-bottom: 72px;
        form {
            display: inline-block;
        }
        .sender-actions-edit {
            color: $color-primary-darker;
            margin-right: 24px;
            background-color: transparent;
            border: none;
            font-size: 17px;
        }
        .sender-actions-delete {
            color: $color-danger;
            background-color: transparent;
            border: none;
            font-size: 17px;
        }
    }
}

.button-message-wrapper {
    display: flex;
    align-items: center;
    button {
        margin-right: 30px;
        margin-top: 0;
    }
    .flash-success-message {
        color: $color-success;
    }
    .flash-error-message {
        color: $color-danger;
    }
}

.form-line-two {
    display: flex;
    width: 100%;
    > div {
        flex: 50%;
    }
    div:first-of-type {
        margin-right: 15px;
    }
}

.juridical-inputs {
    width: 100%;
    &.hidden {
        display: none;
    }
}

