@import 'variables';
@import 'responsive';
@import 'typography';

@import './Components/main';

@import './Common/App/app';
@import './Common/Header/header';
@import './Common/Footer/footer';
@import './Common/Modals/main';
@import './Common/common';
@import './Common/messages';
@import './Common/Modals/firstVisit';

@import './Forms/main';

@import './Pages/main';
