.checkout-complete-wrap {
    margin-top: 70px;
}

.checkout-complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.checkout-complete-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin-bottom: 45px;
    background: $color-white;
    border-radius: 50%;
}

.checkout-complete-title {
    margin-bottom: 40px;
}

.checkout-complete-text {
    max-width: 400px;
    margin-bottom: 50px;
}

.checkout-complete-actions {
    display: flex;
    flex-direction: column;
    width: 100%;

    .button {
        width: 100%;
        margin-bottom: 20px;
    }

    @media only screen and (min-width: $screen-xs) {
        width: auto;

        .button {
            width: auto;
        }
    }

    @media only screen and (min-width: $screen-sm) {
        flex-direction: row;

        .button {
            margin: 0 10px;
        }
    }
}
