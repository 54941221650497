.form-select {
    position: relative;
    display: block;
    width: 100%;
    max-width: none;
    padding: 15px 10px 15px 20px;
    padding-right: 55px;
    border: none;
    background: none;
    color: $color-primary-darkest;
    font-family: inherit;
    font-size: 17px;
    line-height: 21px;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &[type=number] {
        font-weight: bold;
    }

    &::placeholder {
        color: $color-grey-transparent-darkest;
        font-weight: normal;
    }

    > option {
        padding: 20px;
    }
}
