.title-primary {
    font-family: $font-primary;
    font-weight: bold;
    font-size: 64px;
    line-height: 80px;
}

.title-secondary {
    font-family: $font-primary;
    font-size: 52px;
    font-weight: 400;
    line-height: 65px;
}

.title {
    font-family: $font-primary;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
}

.subtitle {
    font-family: $font-primary;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
}

.link-underlined {
    margin-top: 2px;
    padding-bottom: 5px;
    border-bottom: 1px solid $color-grey-transparent-darkest;
    @media (max-width: $width-md) {
        font-size: 15px;
    }
}

.font-normal {
    font-weight: normal;
}
.text-nowrap {
    white-space: nowrap;
}

.container {
    &--content {
        h1 {
            @media (min-width: $width-md) {
                font-size: 52px;
                line-height: 65px;
            }
        }
        font-size: 17px;
        line-height: 25px;
    }
}