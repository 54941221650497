.sizes-form-wrap {
    width: 100%;
}

.sizes-form {
    width: 100%;
}

.sizes-form-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    @media only screen and (min-width: $screen-sm) {
        margin-bottom: 35px;
    }

    &.is-parcel-count {
        margin-top: 25px;

        .field-container {
            max-width: 80px;
            margin-bottom: 0;
        }
    }

    &.is-cod {
        max-height: 0;
        margin-bottom: 0;
        overflow: hidden;
        transition: max-height 0.1s ease-in-out;

        .sizes-form-row-column {
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;

            @media only screen and (max-width: $screen-md) {
                flex-direction: column;
                align-items: flex-start;
            }

            .checkbox {
                margin: 16px 0;
                @media only screen and (min-width: $screen-md) {
                    margin: 0 0 0 30px;
                }
            }

            &.is-bank-account {
                margin-bottom: 0;
            }
        }

        .field-container {
            margin-bottom: 0;
        }

        .sizes-form-row__label {
            min-width: 100%;
            margin-bottom: 10px;

            @media only screen and (min-width: $screen-md) {
                min-width: 305px;
                margin-bottom: 0;
            }
        }

        &.is-visible {
            height: auto;
            max-height: 320px;
            margin-top: 25px;
        }
    }

    &.is-first {
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 25px;

        @media only screen and (min-width: $screen-sm) {
            flex-wrap: wrap;
        }
    }

    &.is-last {
        max-height: 0;
        margin-bottom: 0;
        overflow: hidden;
        transition: max-height 0.1s ease-in-out;

        .sizes-form-row-column {
            margin-top: 20px;
        }

        .field-container {
            margin-bottom: 0;
        }

        &.is-visible {
            height: auto;
            max-height: 440px;
            align-items: flex-start;
        }
    }

    .radio {
        width: 100%;
        margin-bottom: 25px;

        @media only screen and (min-width: $screen-sm) {
            width: 275px;
            margin-right: 30px;
            margin-bottom: 20px;
        }
    }

    .checkbox {
        width: 100%;
        margin-bottom: 0;
    }

    .field-container {
        margin-bottom: 15px;
    }

    .switch-btn {
        height: 50px;
        padding: 10px;
        margin-right: 0;
        margin-left: 5px;
        border: none;
        background: none;

        &.is-desktop {
            display: none;

            img {
                transform: rotate(90deg);
            }

            @media only screen and (min-width: $screen-sm) {
                display: block;
            }
        }

        &.is-mobile {
            display: block;
            margin-top: -15px;

            @media only screen and (min-width: $screen-sm) {
                display: none;
            }
        }

        @media only screen and (min-width: $screen-sm) {
            margin-left: -10px;
            margin-right: 10px;
        }
    }
}

.sizes-form-row__label {
    margin-right: 20px;
}

.sizes-form-row-column-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.sizes-form-row-column {
    width: 248px;
    margin-right: 20px;

    &.is-small {
        width: 136px;
    }

    &.is-cod-price {
        .cod-wrapper {
            display: flex;
            .checkbox {
                margin-left: 30px;
            }

            &__checkbox-container {
                display: grid;
                grid-template-columns: 146px 1fr;
                position: relative;
                top: 11px;
                @media only screen and (min-width: $screen-md) {
                    top: 27px;
                }
                &__link-wrapper {
                    position: relative;
                    top: 18px;
                    @media only screen and (min-width: $screen-md) {
                        top: 2px;
                    }
                }
            }
        }
        .field-container {
            width: 50%;

            @media only screen and (min-width: $screen-md) {
                max-width: 170px;
            }
        }
    }

    &.is-bank-account {
        .field-container {
            @media only screen and (min-width: $screen-md) {
                width: 555px;
            }
        }
    }

    @media only screen and (max-width: $screen-sm - 1) {
        width: 100%;
        margin-right: 0;

        &.is-small {
            margin-right: 20px;
        }
    }

    @media only screen and (max-width: 380px) {
        &.is-small {
            width: 100%;
            margin-right: 0;
        }
    }
}

.sizes-form-submit-btn {
    @media only screen and (max-width: $screen-sm - 1) {
        .button {
            width: 100%;
        }
    }
}
