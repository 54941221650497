.radio {
    position: relative;
    display: flex;
    height: 100px;
    margin-bottom: 20px;
    border-radius: 50px;
    background: $color-white;
    cursor: pointer;

    &.is-small {
        max-height: 70px;

        .radio-bubble {
            max-width: 70px;
        }
    }

    &.is-image {
        min-width: 185px;
        justify-content: center;

        .radio-bubble {
            max-width: none;
            border: none;
        }
    }

    &.is-responsive {
        @media only screen and (max-width: $screen-md - 1) {
            flex-direction: column;
            height: auto;

            .radio-bubble {
                width: 100%;
                padding: 15px 32px;
                margin-bottom: 10px;
                border: none;
                border-radius: 0;
                border-top-right-radius: 50px;
                border-top-left-radius: 50px;
                align-items: flex-start;
            }

            .radio-text {
                padding: 0 32px 20px;
            }

            .radio-checkmark {
                position: absolute;
                right: 0;
                top: 32px;
                margin-right: 40px;
                color: white;
            }
        }
    }

    input {
        opacity: 0;
        position: absolute;
        bottom: 0;
        z-index: -1;

        &:checked {
            ~ .radio-bubble {
                border-color: $color-primary-darkest;
                background: $color-primary-darkest;
                color: $color-white;

                &.is-small-size {
                    background: $color-primary-darkest;
                    border-color: $color-primary-darkest;
                }

                &.is-medium-size {
                    background: $color-danger-light;
                    border-color: $color-danger-light;
                }

                &.is-large-size {
                    background: $color-blue;
                    border-color: $color-blue;
                }

                &.is-image {
                    border: none;
                    background: none;
                    margin-right: 0;
                }
            }

            ~ .radio-checkmark {
                opacity: 1;
                margin-right: 25px;
            }

            ~ .radio-shadow {
                display: block;
            }
        }
    }
}

.radio-bubble {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    margin-right: 20px;
    border: $border-light;
    border-radius: 50px;
    font-size: 32px;
    text-align: center;
    transition: all 0.1s ease-in-out;

    &.is-small-size {
        background: $color-primary-darkest;
        border-color: $color-primary-darkest;
        color: $color-white;
    }

    &.is-medium-size {
        background: $color-danger-light;
        border-color: $color-danger-light;
        color: $color-white;
    }

    &.is-large-size {
        background: $color-blue;
        border-color: $color-blue;
        color: $color-white;
    }

    &.is-small {
        min-width: auto;
        padding: 0 25px;
    }

    &.is-image {
        padding: 0 15px;
        margin-right: -15px;
    }
}

.radio-bubble__extra-text {
    position: relative;
    left: -25px;
    font-size: 17px;

    &.is-hidden {
        display: none;
    }
}

.radio-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding-right: 10px;
}

.radio-text__title {
    font-weight: bold;

    &.has-margin {
        margin-bottom: 5px;
    }
}

.radio-text__subtitle.has-margin {
    margin-bottom: 5px;
}

.radio-text__extra-text {
    font-size: 12px;
}

.radio-checkmark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    font-size: 20px;
    transition: all 0.1s ease-in-out;

    .is-image & {
        margin-right: 0;
    }
}

.radio-shadow {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: $box-shadow;
    border-radius: 50px;
}
