.simple-radio {
    display: flex;
    position: relative;
    cursor: pointer;
    vertical-align: top;
    margin-bottom: 15px;

    &.is-disabled {
        cursor: not-allowed;
    }

    .radio__input {
        input {
            position: absolute;
            opacity: 0;
            &:checked + label:after {
                content: '';
                width: 10px;
                height: 10px;
                z-index: $zIndex-over;
                border-radius: 10px;
                background-color: $color-primary-darkest;
                position: absolute;
                top: 5px;
                left: 5px;
            }
        }
        label {
            position: relative;
            padding: 0 32px;
            &:before {
                content: '';
                z-index: $zIndex-under;
                width: 18px;
                height: 18px;
                border: $border-radio;
                background-color: $color-white;
                border-radius: 18px;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}
