.checkbox {
    display: inline-block;
    margin-bottom: 25px;
    text-align: left;
}

.checkbox-view {
    input {
        opacity: 0;
        position: absolute;
    }

    .checkbox-view__label {
        display: inline-block;
    }

    label {
        padding-left: 30px;
        position: relative;
        font-weight: 300;
        line-height: 25px;
        font-size: 17px;
        margin-bottom: 0;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            width: 17px;
            height: 17px;
            font-size: 17px;
            background-color: $color-white;
        }
    }

    input:checked + .checkbox-view__label label {
        &:before {
            background: url('../../../../media/check.svg') no-repeat center;
            background-color: $color-white;
        }
    }
}
